import React from 'react';
import Helmet from 'react-helmet';

import queryString from 'query-string';
import elasticsearch from 'elasticsearch';
import bodybuilder from 'bodybuilder';

import { Card } from '@blueprintjs/core';

import settings from '../helpers/settings';

import Layout from '../components/layout';
import PrintButton from '../components/PrintButton';
import ZoneDesc from '../components/ZoneDesc';

import useFields from '../hooks/useFields';

import styles from './comparer.module.scss';

const getZonesFromQS = location => {
  const qs = queryString.parse(location.search);
  const zones = qs.eae ? qs.eae.split(',') : [];
  return zones;
};

const client = new elasticsearch.Client({
  host: settings.ELASTICSEARCH_HOST,
  // log: 'trace',
});

const ComparerPage = ({ location }) => {
  const fields = useFields();

  const {
    eae: zaeFields,
    etablissements: etabFields,
  } = fields.reduce((acc, { data: { Key, Ressource } }) =>
    ({ ...acc, [Ressource]: [...(acc[Ressource] || []), Key] }), {});

  const [zones] = React.useState(getZonesFromQS(location));
  const [zonesData, setZonesData] = React.useState([]);
  const [error, setError] = React.useState(false);

  const componentIsMounted = React.useRef(true);

  const getData = React.useCallback(() => {
    const msearch = {
      body: [
        { index: 'zae' },
        bodybuilder()
          .filter('terms', 'id_eae', zones)
          .rawOption('_source', { include: zaeFields })
          .build(),

        { index: 'etablissements' },
        bodybuilder()
          .filter('terms', 'id_eae', zones)
          .rawOption('_source', { include: etabFields })
          .size(10000)
          .build(),
      ],
    };

    client.msearch(msearch, (err, { responses: [zoneHits, etabHits] }) => {
      if (!componentIsMounted) {
        return;
      }

      if (!err) {
        const topSirets = zoneHits?.hits?.hits?.map(
          ({ _source: { top_etablissement: tops } = {} }) => tops.map(({ siret }) => siret)
        ).flat() || [];

        const etabsByZone = etabHits.hits.hits
          .reduce((acc, { _source, _source: { id_eae: idEae } }) => ({
            ...acc,
            [idEae]: [
              ...(acc[idEae] || []),
              _source,
            ],
          }), {});

        setZonesData(
          zoneHits.hits.hits.map(({ _source, _source: { id_eae: idEae } }) => ({
            ..._source,
            etablissements: etabsByZone[idEae].map(etab => ({
              ...etab,
              fields: { ...etab.fields, top: topSirets.includes(etab.siret) },
            })),
          })),
        );
      } else {
        console.error(err);
        setError(true);
      }
    });
  }, [zones, fields]);

  React.useEffect(() => {
    getData();
    return () => { componentIsMounted.current = false; };
  }, [getData]);

  return (
    <>
      <Layout
        topBarProps={{
          pageTitle: "Lister les espaces d'activités",
          returnList: true,
          buttonText: '‹ retour à la liste',
          href: '/espaces-d-activites',
        }}
      >

        <Card className={styles.mainCard}>
          <div className={styles.titleWrapper}>
            <h1 style={{ marginTop: 0 }}>Comparaison d'Espaces d'activités</h1>
            <PrintButton className={styles.printButton} />
          </div>

          {zonesData.length
            ? <ZoneDesc zones={zonesData} />
            : <span>Chargement...</span>}

          {error && (
            <span>Erreur pendant la récupération des données.</span>
          )}
        </Card>
      </Layout>
      <Helmet title="Comparaison d'Espaces d'activités" />
    </>
  );
};

export default ComparerPage;
